module.exports = [{
      plugin: require('/builds/bprg/bprg_website/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/bprg/bprg_website/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false},
    },{
      plugin: require('/builds/bprg/bprg_website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/builds/bprg/bprg_website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"destinationDir":"static","custom":{"families":["Armitage","Armitage-Bold"],"urls":["/armitage.css"]}},
    },{
      plugin: require('/builds/bprg/bprg_website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138584690-1","head":false,"anonymize":true},
    },{
      plugin: require('/builds/bprg/bprg_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
