/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/sass/_global.scss';
import './static/armitage.css';

window['ga-disable-UA-138584690-1'] = true;
